const ROOT_URL = "/api";
export const URL_CONSTANT = {
    JobType: {
        GetJobTypes: ROOT_URL + "/JobType/GetJobTypes",
        GetJobTypesForConfiguration: ROOT_URL + "/Configurations/JobType/GetAllJobTypes",
        GetCQJobTypes: ROOT_URL + "/Configurations/JobType/GetCQJobTypes",
        UpdateJobTypes: ROOT_URL + "/Configurations/JobType/UpdateJobTypes",
        GetJobTypesDetail: ROOT_URL + "/JobType/GetJobTypesDetail"
    },
    RequestForm: {
        GetDefaultConfigurations: ROOT_URL + "/RFQ/GetDefaultConfigurations",
        CreateRFQ: ROOT_URL + "/RFQ/Create",
        UpdateRFQ: ROOT_URL + "/RFQ/Update",
        CloneRFQ: ROOT_URL + "/RFQ/Clone",
        DownLoadFile: ROOT_URL + "/RFQ/DownloadFile",
        GetAllQuoteType: ROOT_URL + "/RFQ/GetAllQuoteTypes",
        GetRFQDetails: ROOT_URL + "/RFQ/Get",
        GetRFQDocuments: ROOT_URL + "/RFQ/GetRFQDocuments",
        DeleteRFQDocument: ROOT_URL + "/RFQ/DeleteRFQDocument",
        DeleteQuantity: ROOT_URL + "/RFQ/DeleteQuantity",
        DeleteTurnTime: ROOT_URL + "/RFQ/DeleteTurnTime",
        UpdateAssemblyFieldValue: ROOT_URL + "/RFQ/UpdateAssemblyFieldValue",
        UpdateAdjustedTurnTimeApprovalStatus: ROOT_URL + "/RFQ/UpdateAdjustedTurnTimeApprovalStatus",
        GetRFQList: ROOT_URL + "/RFQ/GetList",
        GetRFQInfoForContinue: ROOT_URL + "/RFQ/GetRFQInfoForContinue",
        CreateBomHealthAssembly: ROOT_URL + "/RFQ/CreateBomHealthAssembly",
        GetCustomerContactByEmail: ROOT_URL + "/RFQ/GetCustomerContactByEmail",
    },
    Labor: {
        GetLaborQuestions: ROOT_URL + "/Labor/GetLaborQuestions",
        GetLaborActivitiesWithCalculatedDriver: ROOT_URL + "/Labor/GetLaborActivitiesWithCalculatedDriver",
        SubmitLaborAnswers: ROOT_URL + "/Labor/SubmitLaborAnswers",
        UpdateLaborAnswers: ROOT_URL + "/Labor/UpdateLaborAnswers",
        DeleteAssemblyLaborActivities: ROOT_URL + "/Labor/DeleteAssemblyLaborActivities",
        AutoCompleteLaborForPCBNet: ROOT_URL + "/Labor/AutoCompleteLaborForPCBNet"
    },
    Configuration: {
        GetConfigurations: ROOT_URL + "/Configuration/GetConfigurations",
        GetApplicationConfigurations: ROOT_URL + "/Configuration/GetApplicationConfigurations",
        IsClientExist: ROOT_URL + "/Configuration/IsClientExist",
        DecryptQueryString: ROOT_URL + "/Configuration/DecryptQueryString"
    },
    BOM: {
        CreateBOM: ROOT_URL + "/BOM/Create",
        GetPCBLinesByAssemblyId: ROOT_URL + "/BOM/GetPCBLinesByAssemblyId",
        IsScrubMyBomRunning: ROOT_URL + "/BOM/IsScrubMyBomRunning"
    },
    CustomImportWidgetCSSUrl: `${window.location.origin}${process.env.PUBLIC_URL}/CustomImportWidget.css`,
    PCBNetImportWidgetCSSUrl: `${window.location.origin}${process.env.PUBLIC_URL}/PCBNetImportWidget.css`,
    Pricing:
    {
        GetRequestedQuantity: ROOT_URL + "/Pricing/GetRequestedQuantity",
        GetPurchaseData: ROOT_URL + "/Pricing/GetPurchaseData",
        UpdateMethodOfSupply: ROOT_URL + "/Pricing/UpdateMethodOfSupply",
        GetBomSummary: ROOT_URL + "/Pricing/GetBomSummary",
        CheckPricingStatus: ROOT_URL + "/Pricing/CheckPricingStatus",
        RefreshPricing: ROOT_URL + "/Pricing/RefreshPricing",
        UpsertMethodOfSupplySelections: ROOT_URL + "/Pricing/UpsertMethodOfSupplySelections",
        AddAlternateParts: ROOT_URL + "/Pricing/AddAlternateParts"
    },
    PCB: {
        GetPcbQuestions: ROOT_URL + "/PCB/GetPCBQuestions",
        SubmitPCBAnswers: ROOT_URL + "/PCB/SubmitPCBAnswers",
        SubmitPCBWithPricing: ROOT_URL + "/PCB/SubmitPCBWithPricing",
        GetSavedPCBAnswers: ROOT_URL + "/PCB/GetSavedPCBAnswers",
        GetPCBAnswerFields: ROOT_URL + "/PCB/GetPCBAnswerFields"
    },
    Summary: {
        PublishQuoteCQQuote: ROOT_URL + "/Summary/PublishQuoteCQQuote",
        GetSummaryDetails: ROOT_URL + "/Summary/Get"
    },
    QuotePrice: {
        MarkPriceAsSelected: ROOT_URL + "/QuotePrice/MarkPriceAsSelected",
        GetSelectedPrice: ROOT_URL + "/QuotePrice/GetSelectedPrice"
    },
    RFQProcessStatus: {
        GetRFQSteps: ROOT_URL + "/RFQProcessStatus/GetRFQSteps",
        SaveCompletedStep: ROOT_URL + "/RFQProcessStatus/Save",
        GetRFQStepsForConfiguration: ROOT_URL + "/Configurations/RFQSteps/GetRFQStepsForConfiguration",
        UpdateRFQSteps: ROOT_URL + "/Configurations/RFQSteps/UpdateRFQSteps",
        GetRFQProcessStatus: ROOT_URL + "/RFQProcessStatus/GetRFQProcessStatus"
    },
    Checkout: {
        GetOrder: ROOT_URL + "/Order/GetOrder",
        SaveOrder: ROOT_URL + "/Order/SaveOrder",
        CreatePaymentIntent: ROOT_URL + "/Payment/CreatePaymentIntent",
        UpdatePaymentDetails: ROOT_URL + "/Payment/UpdatePaymentDetails",
        GetShippingMethods: ROOT_URL + "/ShippingMethod/Get",
        GetStripePublishableKey: ROOT_URL + "/Configuration/GetStripePublishableKey",
        GetCountries: ROOT_URL + "/Country/Get",
    },
    CheckListBuilder: {
        GetCheckListScreen: ROOT_URL + "/CheckListBuilder/GetCheckListScreen",
        SubmitCheckListBuilder: ROOT_URL + "/RFQ/UpsertRFQChecklist",
        GetCheckListAnswers: ROOT_URL + "/CheckListBuilder/GetCheckListAnswers",
        GetCheckListDetails: ROOT_URL + "/Configurations/CheckList/GetCheckListDetailsForConfiguration",
        UpdateCheckListDetails: ROOT_URL + "/Configurations/CheckList/UpdateCheckList"
    },
    Report: {
        GetAllPricingReport: ROOT_URL + "/Report/GetAllPricingReport",
        GetCostedBOMReport: ROOT_URL + "/Report/GetCostedBOMReport",
        GetQuoteReport: ROOT_URL + "/Report/GetQuoteReport"
    },
    Document: {
        GeneratePreSignedUrl: ROOT_URL + "/Document/GeneratePreSignedUrl",
        InsertDocuments: ROOT_URL + "/Document/InsertDocuments"
    },
    CustomQuote: {
        GetAllUploadedDocuments: ROOT_URL + "/CustomQuote/GetAllUploadedDocuments",
        SubmitCustomQuote: ROOT_URL + "/CustomQuote/SubmitCustomQuote"
    },
    GetMarketingContentBySectionName: ROOT_URL + "/MarketingContent/GetMarketingContentBySectionName",
    RouteList: ROOT_URL + "/Route/GetRouteList",
    GetJargonList: ROOT_URL + "/Jargon/GetJargonList",
    GetJargonListFromConfigurations: ROOT_URL + "/Configurations/Jargon/GetJargonList",
    SaveUpdatedData: ROOT_URL + "/Configurations/Jargon/SaveUpdatedData",
    AlternateParts: {
        GetSuggestedAlternateParts: ROOT_URL + "/AlternateParts/GetSuggestedAlternateParts"
    },
    LogUserLoggedInActivity: ROOT_URL + "/ActivityTrack/LogUserLoggedInActivity",
    MiscConfiguration: {
        GetMiscConfigurations: ROOT_URL + "/Configurations/MiscConfiguration/GetMiscConfigurations",
        UpdateMiscConfigurations: ROOT_URL + "/Configurations/MiscConfiguration/UpdateMiscConfigurations"
    },
    Account: {
        IsUserPortCQAdmin: ROOT_URL + "/Account/IsUserPortCQAdmin"
    },
    ShippingMethods: {
        GetShippingMethods: ROOT_URL + "/Configurations/ShippingMethods/GetShippingMethods",
        UpdateShippingMethods: ROOT_URL + "/Configurations/ShippingMethods/UpdateShippingMethods"
    },
    MarketingContent: {
        GetAllMarketingContents: ROOT_URL + "/MarketingContent/GetAllMarketingContents",
        UpdateMarketingContent: ROOT_URL + "/MarketingContent/UpdateMarketingContent",
    },
    BOMHealth: {
        BOMHealthReport: ROOT_URL + "/BOMHealth/EmailBOMHealthReport",        
    },
    BOMHealthMonitor: {        
        CreateBomHealthMonitor: ROOT_URL + "/BOMHealthMonitor/CreateBomHealthMonitor",
        GetScheduleByAssemblyId: ROOT_URL + "/BOMHealthMonitor/GetScheduleByAssemblyId",
        GetBomHealthMonitorHistory: ROOT_URL + "/BOMHealthMonitor/GetBomHealthMonitorHistory",
        DeleteBomHealthMonitorRequest: ROOT_URL + "/BOMHealthMonitor/DeleteBomHealthMonitorRequest", 
        UpdateBomHealthMonitor: ROOT_URL + "/BOMHealthMonitor/UpdateBomHealthMonitor"
    },
    ContactDetails: {
        GetByAssemblyId: ROOT_URL + "/ContactDetails/GetByAssemblyId",
        Save: ROOT_URL + "/ContactDetails/Save"
    },
    PCBNet: {
        GetQuoteDetails: ROOT_URL + "/PCBNet/GetQuoteDetails",
        CreatePCBNetAssembly: ROOT_URL + "/PCBNet/CreatePCBNetAssembly"
    },
    AssemblyChat: {
        GetAssemblyChat: ROOT_URL + "/AssemblyChat/GetAssemblyChat",
        SaveAssemblyChatMessage: ROOT_URL + "/AssemblyChat/SaveAssemblyChatMessage",
        GetUnreadMessageCount: ROOT_URL + "/AssemblyChat/GetUnreadMessageCount"
    }
};